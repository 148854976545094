<template>
  <CModalExtended
    :title="$t('label.errors')"
    color="dark"
    size="xl"
    :show.sync="modalActive"
  >
  <CRow>
    <CCol class="d-flex justify-content-end">
      <CButton 
        shape="square" 
        color="success" 
        @click="onBtnExportErrors(staffList)"
      >
      <CIcon name="cil-file-excel" />&nbsp; XLSX
    </CButton>
    </CCol>
  </CRow>
    <dataTableExtended
      class="align-center-row-datatable"
      hover
      small
      sorter
      column-filter
      :table-filter="tableText.tableFilterText"
      :items-per-page-select="tableText.itemsPerPageText"
      :noItemsView="tableText.noItemsViewText"
      :items="formatedItems"
      :fields="fields"
      :loading="isLoading"
      :items-per-page="5"
      :active-page="activePage"
      pagination
    >
    </dataTableExtended>

    <template #footer>
      <CButton shape="square" color="wipe" @click="toggle(false)">
        <CIcon name="x" />&nbsp; {{ $t("button.exit") }}
      </CButton>
    </template>
  </CModalExtended>
</template>

<script>
import ModalMixin from "@/_mixins/modal";
import { tableText } from "@/_helpers/funciones";
import Reportes from '@/_mixins/reportes';
import StaffMixin from '@/_mixins/staff';

function fields() {
  return [
    {
      key: "Nro",
      label: "#",
      _style: "width:1%;",
      filter: false,
    },
    { key: "NumberId", label: this.$t("label.IdNumber"),_classes:'text-center' },
    { key: "Name", label: this.$t("label.name"),_classes:'text-center' },
    { key: "Column", label: this.$t("label.column"),_classes:'text-center' },
    { key: "Value", label: this.$t("label.value"),_classes:'text-center' },
    { key: "Error", label: this.$t("label.error"),_classes:'text-center' },
  ];
}
function cellTableClasses() {
  return {
    Nro: "align-middle",
    NumberId: "align-middle",
    Name: "align-middle",
    Column: "align-middle",
    Value: "align-middle",
    Error: "align-middle",
  };
}

//Data
function data() {
  return {
    modalActive: this.modal,
    isLoading: false,
    activePage: 1,
  };
}

//Methods
function toggle(newVal) {
  this.modalActive = newVal;
}

//Computeds
function formatedErrors() {
  if (this.staffList.length == 0) return [];

  let arrErrors = [];
  for (let index = 0; index < this.staffList.length; index++) {
    if (!Array.isArray(this.staffList[index].JsonError)) {
      for (
        let j = 0;
        j < this.staffList[index].JsonError.ErrorJson.length;
        j++
      ) {
        arrErrors.push({
          NumberId: this.staffList[index].NumberId,
          Name: this.staffList[index].Name,
          Column: this.staffList[index].JsonError.ErrorJson[j].ColumnName,
          Value: this.staffList[index].JsonError.ErrorJson[j].ColumnValue,
          Error:
            this.$i18n.locale == "es"
              ? this.staffList[index].JsonError.ErrorJson[j].ErrorMessage
              : this.staffList[index].JsonError.ErrorJson[j].ErrorMessageEn,
        });
      }
    }
  }

  return arrErrors;
}
function formatedItems() {
  let index = 1;
  return this.formatedErrors.map((item) =>
    Object.assign({}, item, {
      Nro: index++,
      _cellClasses: this.cellTableClasses,
    })
  );
}

export default {
  name: "errors-modal",
  mixins: [ModalMixin, Reportes, StaffMixin],
  props: {
    modal: Boolean,
    staffList: {
      type: Array,
      default: () => [],
    },
  },
  data,
  methods: {
    toggle,
  },
  computed: {
    fields,
    formatedItems,
    cellTableClasses,
    formatedErrors,
    tableText,
  },
  watch: {
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
  },
};
</script>